<template>
  <form
    class="UserForm"
    @submit.prevent="onSubmit">
    <div class="Header">
      <UserIcon />
      <h2>{{ isNewUser ? mixWB('CREATE_USER') : user.fullName }}</h2>
      <ToggleButton
        v-if="currentUser.isAdmin || currentUser.isSuperUser"
        class="ToggleIsActive"
        groupName="isActive"
        value="isActive"
        :text="mixWB('SET_ACTIVE')"
        :isSelected="data.isActive || false"
        @check="onToggleIsActive" />
    </div>
    <ErrorText
      :text="error"
      v-if="error" />
    <InputField
      formGroupName="user-form"
      :value="data.fullName"
      :placeholder="mixWB('FULL_NAME')"
      name="fullName"
      :isRequired="true"
      :disabled="!canEdit || isSaving"
      @on-update="onUpdate" />

    <InputField
      formGroupName="user-form"
      :value="data.initials"
      :placeholder="mixWB('INITIALS')"
      name="initials"
      :isRequired="true"
      :disabled="!canEdit || isSaving"
      @on-update="onUpdate" />

    <InputField
      formGroupName="user-form"
      :value="data.email"
      :placeholder="mixWB('EMAIL')"
      name="email"
      :isRequired="true"
      :disabled="!canEditEmail || isSaving"
      @on-update="onUpdate" />

    <InputField
      formGroupName="user-form"
      :value="data.phoneNumber"
      :placeholder="mixWB('PHONE_NUMBER')"
      name="phoneNumber"
      :isRequired="true"
      :disabled="!canEdit || isSaving"
      @on-update="onUpdate" />

    <div
      v-if="currentUser.isAdmin || currentUser.isSuperUser"
      class="Roles">
      <h3>{{ mixWB('ROLES') }}</h3>
      <Checkbox
        :name="roles.ROLE_SCREENER"
        :isSelected="data.roles.includes(roles.ROLE_SCREENER)"
        :text="mixWB('ROLE_SCREENER')"
        @checkbox-click="onRoleChange" />
      <Checkbox
        :name="roles.ROLE_SUPER_USER"
        :isSelected="data.roles.includes(roles.ROLE_SUPER_USER)"
        :text="mixWB('ROLE_SUPER_USER')"
        @checkbox-click="onRoleChange" />
    </div>

    <Button
      v-if="canEdit"
      :isLoading="isSaving"
      :text="mixWB('SAVE')"
      type="submit" />

    <Accordion
      :title="mixWB('CHANGE_PASSWORD')"
      :bodyComponent="ChangePasswordForm"
      :bodyComponentProps="{
        allwaysUseWithCurrentPassword: true
      }"
      :isOpenOnLoad="false"
      v-if="user && currentUser.id == user.id" />

    <Accordion
      v-if="user && user.overallStats && user.roles.some(x=> ['screener', 'admin'].includes(x))"
      :title="mixWB('STAT_TABLE_TITLE')"
      :bodyComponent="Stat"
      :bodyComponentProps="{
        stats: user
      }"
      :padding="'none'" />
  </form>
</template>

<script>
import { set } from 'lodash-es'
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus'
import InputField from '@/components/FormElements/InputField.vue'
import Button from '@/components/Buttons/Button.vue'
import Checkbox from '@/components/FormElements/Checkbox.vue'
import { roles } from '@/globals/javascript/roles.js'
import User from '@/globals/javascript/models/User'
import ToggleButton from '@/components/FormElements/ToggleButton.vue'
import UserIcon from '@/assets/svg/user.svg?inline'
import { functions } from '@/firebase/init'
import { getErrorMessage } from '@/firebase/errors'
import ErrorText from '@/components/FormElements/ErrorText.vue'
import ChangePasswordForm from '@/components/Forms/ChangePasswordForm.vue'
import Accordion from '@/components/Accordion.vue'
import Stat from '@/components/Stat.vue'

export default {
  name: 'User',
  data() {
    return {
      isSaving: false,
      data: {
        fullName: '',
        initials: '',
        email: '',
        phoneNumber: '',
        roles: [],
        isActive: true,
      },
      roles,
      error: null,
      ChangePasswordForm,
      Stat,
    }
  },
  computed: {
    ...mapGetters([
      'currentAccount',
      'currentUser',
      'allUsers',
    ]),
    isNewUser() {
      return this.$route.name === 'AccountUserNew'
    },
    user() {
      if (this.isNewUser) {
        return null
      }

      if (this.currentUser.id === this.$route.params.userID) {
        // In case admin is looking on a different Account, the Admin's user will not be in allUsers
        return this.currentUser
      }

      return this.allUsers.find((user) => user.id === this.$route.params.userID)
    },
    canEdit() {
      if (this.isNewUser) {
        return this.currentUser.isSuperUser || this.currentUser.isAdmin
      }

      return this.currentUser.isSuperUser
        || this.currentUser.isAdmin
        || this.currentUser.id === this.user?.id
    },
    canEditEmail() {
      return this.canEdit && (!this.user || this.user.email.length === 0)
    },
  },
  methods: {
    onUpdate({ name, value }) {
      set(this.data, name, value)
    },
    onToggleIsActive() {
      this.onUpdate({ name: 'isActive', value: !this.data.isActive })
    },
    onRoleChange(name) {
      if (this.data.roles.includes(name)) {
        this.data.roles = this.data.roles.filter((role) => role !== name)
      }
      else {
        this.data.roles.push(name)
      }
    },
    onSubmit() {
      this.isSaving = true
      this.error = null
      EventBus.$emit('validate-field', 'user-form')

      // Check all required fields
      if (!this.data.fullName
       || !this.data.initials
       || !this.data.email
       || !this.data.phoneNumber) {
        this.isSaving = false
        return
      }

      const user = new User({
        ...this.user,
        ...this.data,
        accountID: this.currentAccount.id,
      })

      if (this.isNewUser) {
        functions.httpsCallable('createNewUser')({
          fullName: user.fullName,
          initials: user.initials,
          email: user.email,
          phoneNumber: user.phoneNumber,
          roles: user.roles,
          accountID: user.accountID,
          company: this.currentAccount.name,
          isActive: user.isActive,
        }).then(() => {
          this.isSaving = false
          this.$router.push({ name: 'AccountUsers' })
        }).catch((error) => {
          this.isSaving = false

          this.error = getErrorMessage(error.details.code, error.message)
        })
      }
      else {
        this.$store.dispatch('updateUser', { user })
          .then(() => {
            this.isSaving = false
          })
      }
    },
  },
  components: {
    InputField,
    Button,
    Checkbox,
    ToggleButton,
    UserIcon,
    ErrorText,
    Accordion,
  },
  created() {
    if (this.user) {
      Object.keys(this.data).forEach((key) => {
        if (typeof this.user[key] !== 'undefined') {
          this.data[key] = this.user[key]
        }
      })
    }
  },
}
</script>

<style lang="stylus" scoped>

  .Header
    margin-bottom 20px
    display flex
    align-content center
    svg
      width 20px
      margin-right 15px
    .ToggleIsActive
      margin-left auto
      display flex
      flex-direction row-reverse
      >>> .ButtonWrap
        margin-left 10px
        margin-right 0
  .Roles
    padding 10px
    margin-bottom 10px
    border 1px solid $color_grey_lighter
    background $color_grey_lightest
    h3
      margin-bottom 10px
  >>> .Accordion
    margin-top 30px
    .Inner
      overflow auto
</style>
