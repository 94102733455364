import { mixWB } from '@/globals/javascript/_util/mixins'

const errorMessages = {
  'auth/email-already-exists': () => mixWB('AUTH_EMAIL_ALREADY_EXISTS'),
  'auth/invalid-email': () => mixWB('AUTH_INVALID_EMAIL'),
  'auth/invalid-password': () => mixWB('AUTH_INVALID_PASSWORD'),
  'auth/wrong-password': () => mixWB('AUTH_WRONG_PASSWORD'),
}

export const getErrorMessage = (code, message) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error({ code, message })
  }

  return errorMessages[code]?.() ?? message
}
