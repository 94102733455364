<template>
  <div class="Checkbox">
    <div
      class="Inner"
      @click="onClick">
      <div class="CheckWrap">
        <CheckIcon v-if="isSelected" />
      </div>
      <span
        v-if="text"
        class="Text"
        v-html="text"/>
    </div>
  </div>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'

export default {
  name: 'Checkbox',
  props: {
    name: {
      type: String,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    onClick() {
      this.$emit('checkbox-click', this.name)
    },
  },
  components: {
    CheckIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .Checkbox
    display flex
    margin-bottom 10px

  .Inner
    display flex
    align-items center
    cursor pointer

  .CheckWrap
    box(30px)
    margin-right 10px
    flex-shrink 0
    background-color #fff
    border 1px solid $color_grey_dark
    padding 4px
    svg
      fill $color_primary
</style>
