<template>
  <form
    class="ChangePasswordForm"
    @submit.prevent="onChangePassword">
    <div
      v-if="successMessage"
      class="SuccessMessage">{{ mixWB('PASSWORD_CHANGED') }}</div>

    <InputField
      v-if="useWithCurrentPassword"
      icon="password"
      type="password"
      name="currentPassword"
      isRequired
      autoFocus
      :placeholder="mixWB('CURRENT_PASSWORD')"
      :value="currentPassword"
      @on-update='onFieldUpdate' />

    <InputField
      icon="password"
      type="password"
      name="password"
      isRequired
      :placeholder="mixWB('NEW_PASSWORD')"
      :value="password"
      @on-update='onFieldUpdate' />

    <InputField
      icon="password"
      type="password"
      name="passwordAgain"
      isRequired
      :placeholder="mixWB('PASSWORD_AGAIN')"
      :value="passwordAgain"
      @on-update='onFieldUpdate' />

    <span
      class="ErrorText"
      v-if="errorText">{{ errorText }}</span>
    <Button
      type="submit"
      :text="mixWB('CHANGE_PASSWORD')"
      :isLoading="isLoading" />
  </form>
</template>

<script>
import { auth, firebase } from '@/firebase/init'
import InputField from '@/components/FormElements/InputField.vue'
import Button from '@/components/Buttons/Button.vue'
import { getErrorMessage } from '@/firebase/errors'

export default {
  name: 'ChangePasswordForm',
  props: {
    onSuccessfullChanged: {
      type: Function,
      required: false,
      default: () => null,
    },
    allwaysUseWithCurrentPassword: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      tryWithCurrentPassword: false,
      currentPassword: '',
      password: '',
      passwordAgain: '',
      errorText: '',
      isLoading: false,
      successMessage: false,
    }
  },
  computed: {
    useWithCurrentPassword() {
      return this.tryWithCurrentPassword || this.allwaysUseWithCurrentPassword
    },
  },
  methods: {
    doUpdatePasswordWithLogin() {
      const user = auth.currentUser

      const credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        this.currentPassword,
      )
      return user.reauthenticateWithCredential(credential)
        .then(this.doUpdatePassword)
    },
    doUpdatePassword() {
      return auth.currentUser.updatePassword(this.password)
    },
    onChangePassword() {
      if (this.isLoading) return
      this.errorText = ''

      if (this.useWithCurrentPassword && this.currentPassword.length === 0) {
        return
      }

      if (this.password.length < 6) {
        this.errorText = this.mixWB('PASSWORD_MIN_LENGTH')
        return
      }

      if (this.password !== this.passwordAgain) {
        this.errorText = this.mixWB('PASSWORD_NOT_MATCH')
        return
      }
      this.isLoading = true

      const update = this.useWithCurrentPassword
        ? this.doUpdatePasswordWithLogin()
        : this.doUpdatePassword()

      update
        .then(() => this.$store.dispatch('updateUser', {
          user: {
            id: auth.currentUser.uid,
            mustUpdatePassword: false,
          },
        }))
        .then(() => {
          this.isLoading = false
          this.successMessage = true
          setTimeout(this.onSuccessfullChanged, 1000)
        })
        .catch((err) => {
          this.isLoading = false
          if (err.code === 'auth/requires-recent-login') {
            this.errorText = this.mixWB('PASSWORD_NEED_REAUTH')
            this.tryWithCurrentPassword = true
          }
          else {
            this.errorText = getErrorMessage(err.code, err.message)
          }
        })
    },
    onFieldUpdate(field) {
      this.errorText = ''
      this[field.name] = field.value
    },
  },
  components: {
    InputField,
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .ErrorText
    display block
    margin-bottom 10px
    color $color_error
    font-size 0.875rem
  .SuccessMessage
    text-align center
    color $color_primary
    margin-bottom 10px
</style>
